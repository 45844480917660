import styled from 'styled-components';

import chevronRightBlack from '../../../images/common/chevron-right-black.svg';

export const BgImg = styled.div<{ clientImg: string | undefined }>`
  width: 430px;
  height: 100%;
  border-top-right-radius: 36px 36px;
  border-bottom-right-radius: 36px 36px;
  border: 1px solid var(--lgray);
  display: flex;
`;

export const MainContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 0px;
  border-radius: 36px 36px;
  box-shadow: var(--shadow);
  .slick-slider {
    .slick-arrow {
      top: calc(50% - 15px);
      transform: unset;
      cursor: pointer;
      &.slick-prev {
        left: -60px;
        transform: rotate(180deg);
        &::before {
          background-image: url('${chevronRightBlack}');
          background-size: 20px 20px;
          display: inline-block;
          width: 20px;
          height: 20px;
          content: '';
        }
      }
      &.slick-next {
        right: -60px;
        &::before {
          background-image: url('${chevronRightBlack}');
          background-size: 20px 20px;
          display: inline-block;
          width: 20px;
          height: 20px;
          content: '';
        }
      }
    }
  }
`;

export const SlideContainer = styled.div`
  height: 460px;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: space-between;
    height: 625px;
  }

  @media (max-width: 768px) {
    height: 575px;
  }
`;

export const LeftSlide = styled.div<{ bgColor: string | undefined }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 3.5rem 2.5rem;
  border-top-left-radius: 36px 36px;
  border-bottom-left-radius: 36px 36px;
  border-left: 2px solid whitesmoke;
  border-bottom-right-radius: 72px 72px;
  background-color: ${(props) => `${props.bgColor}`};
  @media (max-width: 992px) {
    min-height: 300px;
    width: auto;
    border-bottom-left-radius: 0px 0px;
    border-top-right-radius: 36px 36px;
  }
  @media (max-width: 768px) {
    min-height: 280px;
    padding: 2.5rem 3rem;
  }
  @media (max-width: 568px) {
    padding: 3rem 2rem;
    min-height: 380px;
    border-left: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 5rem;
  img {
    width: 260px;
  }
  @media (max-width: 992px) {
    width: auto;
    justify-content: center;
    img {
      width: 240px;
    }
    .ML-logo {
      margin-left: -15px;
    }
  }
  @media (max-width: 768px) {
    img {
      width: 220px;
    }
  }
  @media (max-width: 524px) {
    img {
      width: 200px;
    }
  }
`;

/* elPiki: 29/09/2021 */
export const FullSlide = styled.div<{ bgColor: string | undefined }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 90%;
  padding: 20px;
  padding-left: 60px;
  border-radius: 36px 36px;
  background-color: ${(props) => `${props.bgColor}`};
`;
/* Hasta aca  */

export const ClientName = styled.p`
  font-family: Rubik;
  color: var(--dwhite);
  font-weight: bold;
  font-size: 32px;
  text-align: left;
  padding-bottom: 10px;
  margin-top: 2rem;
  @media (max-width: 992px) {
    font-size: 28px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const ClientSubtitle = styled.p`
  font-family: Rubik;
  color: #c9e0f8;
  font-size: 24px;
  text-align: left;
  @media (max-width: 992px) {
    font-size: 20px;
  }
`;

export const SlideText = styled.div<{ color: string | undefined }>`
  color: var(--white);
  font-family: Rubik;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 400;
  .big {
    color: ${(props) => `${props.color}`};
    font-size: 56px;
    font-weight: bold;
    margin: 20px 0px 42px 0px;
  }
  .p-color {
    color: ${(props) => `${props.color}`};
  }
  @media (max-width: 992px) {
    margin: 0.5rem 0;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CasesBtn = styled.span`
  height: 56px;
  width: 218px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 32px;
  background-color: var(--blue);
  margin-left: 20%;
  margin-top: 40px;
  &.hide {
    visibility: hidden;
  }
  &:hover {
    background-color: var(--lblue);
    color: var(--white);
  }
`;

export const BdFilter = styled.div`
  background-color: rgba(4, 30, 66, 0.6);
  backdrop-filter: blur(3px);
`;

export const ClientAvatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-right: 20px;
`;

export const DiagonalRight = styled.div`
  height: 50px;
  margin-top: 0px;
  background: var(--dblue);
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  @media (min-width: 992px) {
    height: 120px;
    margin-top: 0px;
  }
`;

export const DiagonalLeft = styled.div`
  height: 50px;
  margin-top: -50px;
  background: white;
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  @media (min-width: 992px) {
    height: 120px;
    margin-top: -120px;
  }
`;

export const CaseStudyLink = styled.a<{
  color?: 'lblue' | 'dblue';
}>`
  color: ${({ color = 'lblue' }) => `var(--${color})`};
  font-family: Rubik;
  font-size: 20px;
  text-decoration: underline;
`;
