import styled from 'styled-components';

export const DiagonalTopStyled = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  @media (min-width: 992px) {
    height: 100px;
  }
`;

export const DiagonalTopOutter = styled.div<{ color: string }>`
  background: var(--lgray);
  clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
  padding: 40px 0;
  width: 100%;
  position: absolute;
  top: -40px;
  left: 0;
  z-index: 1;
  @media (min-width: 992px) {
    top: 0;
    clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
    padding: 50px 0;
  }
`;

export const DiagonalTopInner = styled.div<{ color: string }>`
  background: var(--dblue);
  clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
  padding: 20px 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  @media (min-width: 992px) {
    clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
    padding: 50px 0;
  }
`;
