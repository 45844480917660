import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-hook-inview';
import { useTranslation } from 'react-i18next';

import Logo10 from '../../../images/home/ank-logo-gris.png';
import Logo3 from '../../../images/home/logo-claro.svg';
import Logo12 from '../../../images/home/logo-classdojo-gray03.png';
import Logo6 from '../../../images/home/logo-enel.svg';
import Logo7 from '../../../images/home/logo-etermax.svg';
import Logo2 from '../../../images/home/logo-habitat.svg';
import Logo1 from '../../../images/home/logo-mercadolibre.svg';
import Logo5 from '../../../images/home/logo-mimic.svg';
import Logo11 from '../../../images/home/logo-nx.svg';
import Logo9 from '../../../images/home/logo-sirum_gray.svg';
import Logo4 from '../../../images/home/logo-wildlife.svg';
import Logo8 from '../../../images/home/rappi-seeklogo.com.svg';
import Testimonials from '../testimonials/Testimonials';
import FeaturedCompanies from './FeaturedCompanies';
import {
  CompaniesStyled,
  Title,
  LogosContainer,
  Logo,
  CasesBtn,
  CasesBtnsContainer,
} from './style';

interface Props {}

const Companies: FC<Props> = () => {
  const { t } = useTranslation();
  const [targetRef, isInViewport] = useInView({
    unobserveOnEnter: true,
  });
  return (
    <CompaniesStyled>
      <div ref={targetRef} className="container">
        <Title
          className={
            isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
          }
        >
          {t('HomeCompaniesTitle')}
        </Title>
        <Testimonials />
        <LogosContainer>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img
              src={Logo1}
              alt="logo"
              style={{ height: '2.3rem', width: 'auto' }}
            />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img src={Logo2} alt="logo" />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img src={Logo3} alt="logo" />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img src={Logo4} alt="logo" />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img src={Logo5} alt="logo" />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img src={Logo6} alt="logo" />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img
              src={Logo7}
              alt="logo"
              style={{ height: '2rem', width: 'auto' }}
            />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img src={Logo8} alt="logo" />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img
              src={Logo9}
              alt="logo"
              style={{ height: '3rem', width: 'auto' }}
            />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img
              src={Logo10}
              alt="logo"
              style={{ height: '2.5rem', width: 'auto' }}
            />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img src={Logo11} alt="logo" />
          </Logo>
          <Logo
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : 'hide'
            }
          >
            <img
              src={Logo12}
              alt="logo"
              style={{ height: '4rem', width: 'auto' }}
            />
          </Logo>
        </LogosContainer>
        <CasesBtnsContainer>
          <Link
            to="/solutions#usecases"
            className={
              isInViewport
                ? 'animate__animated animate__fadeInUp animate__delay-4s'
                : 'hide'
            }
          >
            <CasesBtn>{t('HomeCompaniesButton')}</CasesBtn>
          </Link>
          <a
            href="https://www.youtube.com/watch?v=1vniZ3POJm0&list=PLuvaTF_EJ8cYnzwA3hfYAap8Lupw9q8VY&ab_channel=MuttData"
            target="_blank"
            rel="noopener noreferrer"
            className={
              isInViewport
                ? 'animate__animated animate__fadeInUp animate__delay-5s'
                : 'hide'
            }
          >
            <CasesBtn>{t('HomeWatchSuccessCasesButton')}</CasesBtn>
          </a>
        </CasesBtnsContainer>

        <div style={{ marginTop: '100px' }}>
          <FeaturedCompanies />
        </div>
      </div>
    </CompaniesStyled>
  );
};

export default Companies;
