import React, {
  useCallback,
  useState,
  ChangeEvent,
  FormEvent,
  FocusEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';

import { rudderanalytics } from '../../../rudderanalytics';
import {
  ErrorMessage,
  Form,
  Input,
  InputContainer,
  SubscribeButton,
  Title,
} from './style';
import useSubscribe from './useSubscribe';

const NewsletterSubscriptionForm = () => {
  const { t } = useTranslation();
  const [subscribe, subscribeState] = useSubscribe();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [wasTouched, setWasTouched] = useState(false);

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = EmailValidator.validate(value);
    setError(isValid ? null : 'Must be a valid email');
    setEmail(value);
  }, []);
  const handleBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
    setWasTouched(true);
  }, []);
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (error) return;
      rudderanalytics.identify(email, {
        email: email,
      });
      subscribe({ email }).then(() => {
        setEmail('');
        setError(null);
        setWasTouched(false);
        history.push('/subscription-success');
      });
    },
    [error, email, subscribe, history]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Title
        dangerouslySetInnerHTML={{ __html: t('NewsletterSubscriptionTitle') }}
      />
      <InputContainer>
        <Input
          type="text"
          name="email"
          placeholder="Your work email"
          value={email}
          onChange={handleEmailChange}
          onBlur={handleBlur}
        />
        <SubscribeButton
          disabled={subscribeState.loading || (wasTouched && !!error)}
        >
          {subscribeState.loading ? 'Loading...' : 'Subscribe'}
        </SubscribeButton>
        <ErrorMessage>{wasTouched && error}</ErrorMessage>
        <ErrorMessage>
          {!!subscribeState.error &&
            'Sorry, an error occurred, please try again later'}
        </ErrorMessage>
      </InputContainer>
    </Form>
  );
};

export default NewsletterSubscriptionForm;
