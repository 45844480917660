import styled from 'styled-components';

export const ProcessStyled = styled.div`
  margin-top: 54px;
  margin-bottom: 125px;
  .container {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  @media (min-width: 992px) {
    margin-top: 160px;
    /* margin-bottom: 270px; */
  }
`;

export const ImageContainer = styled.div`
  max-height: 552px;
  max-width: 552px;
  > img {
    object-fit: cover;
    width: 100%;
    &:first-of-type {
      display: none;
      @media (min-width: 992px) {
        display: inline;
      }
    }
    &:last-of-type {
      display: inline;
      margin-top: 47px;
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
  > h1 {
    display: block;
    @media (min-width: 992px) {
      display: none;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 456px;
  @media (min-width: 1024px) {
    margin-left: 24px;
  }
`;

export const Title = styled.h1`
  color: var(--black);
  font-family: Rubik;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`;

export const Text = styled.p`
  color: var(--gray);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    margin-top: 160px;
  }
  @media (min-width: 992px) {
    margin-top: 30px;
  }
`;

export const Btn = styled.span`
  height: 56px;
  width: 189px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 32px;
  background-color: var(--blue);
  &:hover {
    background-color: var(--lblue);
    color: var(--white);
  }
  @media (min-width: 992px) {
    width: 218px;
  }
`;
