import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-hook-inview';
import { useTranslation } from 'react-i18next';

import ImageMb from '../../../images/home/process-image-mb.png';
import Image from '../../../images/home/process-image.png';
import {
  ProcessStyled,
  ImageContainer,
  TextContainer,
  Title,
  Text,
  Btn,
} from './style';

interface Props {}

const Process: FC<Props> = () => {
  const { t } = useTranslation();
  const [targetRef, isInViewport] = useInView({
    unobserveOnEnter: true,
  });
  return (
    <ProcessStyled>
      <div ref={targetRef} className="container">
        <ImageContainer
          className={
            isInViewport
              ? 'animate__animated animate__fadeInLeft animate__fadeIn animate__fast'
              : ''
          }
        >
          <Title>{t('HomeProcessTitle')}</Title>
          <img src={Image} alt="Gantt diagrams towards a quick launch" />
          <img src={ImageMb} alt="Gantt diagrams towards a quick launch" />
        </ImageContainer>
        <TextContainer
          className={
            isInViewport
              ? 'animate__animated animate__fadeInRight animate__fadeIn animate__fast'
              : ''
          }
        >
          <Title>{t('HomeProcessTitle')}</Title>
          <Text>{t('HomeProcessText')}</Text>
          <Link to="/solutions#process">
            <Btn>{t('HomeProcessButton')}</Btn>
          </Link>
        </TextContainer>
      </div>
    </ProcessStyled>
  );
};

export default Process;
