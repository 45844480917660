import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import LogoAnk_color from './assets/logo-ank_color.png';
import LogoClassDojo_dark from './assets/logo-classdojo.png';
import LogoEtermax_dark from './assets/logo-etermax_dark.svg';
import LogoHabitat_color from './assets/logo-habitat_dark.svg';
import LogoMercadoLibre_color from './assets/logo-mercadolibre-color.png';
import LogoSirum_color from './assets/logo-sirum_color.svg';
import {
  MainContainer,
  SlideText,
  SlideContainer,
  ClientName,
  ClientSubtitle,
  LeftSlide,
  LogoContainer,
  CaseStudyLink,
} from './style';

interface TestimonialProps {
  texts: string[];
  link?: string;
  signature: string;
  jobPosition: string;
  logo: string;
  logoAlt: string;
  color: 'blue' | 'dblue' | 'lblue';
}

const Testimonial = ({
  texts,
  link,
  signature,
  jobPosition,
  logo,
  logoAlt,
  color,
}: TestimonialProps) => {
  const { t } = useTranslation();
  return (
    <SlideContainer style={{ justifyContent: 'flex-start' }}>
      <LeftSlide
        bgColor={
          color === 'blue'
            ? 'var(--blue)'
            : color === 'dblue'
            ? 'var(--dblue)'
            : 'var(--lblue)'
        }
      >
        {texts.map((text: string, index: number) => (
          <SlideText
            key={index}
            color={
              color === 'blue'
                ? 'var(--blue)'
                : color === 'dblue'
                ? 'var(--dblue)'
                : 'var(--lblue)'
            }
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ))}
        {link && (
          <CaseStudyLink
            color={color === 'lblue' ? 'dblue' : 'lblue'}
            href={link}
            target="_blank"
          >
            {t('HomeTestimonialLink')}
          </CaseStudyLink>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <ClientName>{signature}</ClientName>
          <ClientSubtitle>{jobPosition}</ClientSubtitle>
        </div>
      </LeftSlide>
      <LogoContainer>
        <img src={logo} alt={logoAlt} />
      </LogoContainer>
    </SlideContainer>
  );
};

const Testimonials: FC = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 16000,
    arrow: true,
    accessibility: true,
  };

  return (
    <MainContainer>
      <Slider {...settings}>
        <Testimonial
          texts={[
            t('HomeTestimonialTextHabitat01'),
            t('HomeTestimonialTextHabitat02'),
          ]}
          link="https://muttdata.ai/blog/2022/11/01/habitat-case-study.html"
          signature={t('HomeTestimonialSignatureHabitat')}
          jobPosition={t('HomeTestimonialJobPositionHabitat')}
          logo={LogoHabitat_color}
          logoAlt="Logo Habitat"
          color="blue"
        />
        <Testimonial
          texts={[t('HomeTestimonialTextML01'), t('HomeTestimonialTextML02')]}
          link="https://muttdata.ai/blog/2023/01/16/case-study-meli-bidding.html"
          signature={t('HomeTestimonialSignatureML')}
          jobPosition={t('HomeTestimonialJobPositionML')}
          logo={LogoMercadoLibre_color}
          logoAlt="Logo MercadoLibre"
          color="dblue"
        />
        <Testimonial
          texts={[
            t('HomeTestimonialTextSirum01'),
            t('HomeTestimonialTextSirum02'),
          ]}
          link="https://muttdata.ai/blog/2022/12/21/case-study-sirum.html"
          signature={t('HomeTestimonialSignatureSirum')}
          jobPosition={t('HomeTestimonialJobPositionSirum')}
          logo={LogoSirum_color}
          logoAlt="Logo Sirum"
          color="lblue"
        />
        <Testimonial
          texts={[t('HomeTestimonialText01Ank'), t('HomeTestimonialText02Ank')]}
          link="https://muttdata.ai/blog/2022/12/01/case-study-ank.html"
          signature={t('HomeTestimonialSignatureAnk')}
          jobPosition={t('HomeTestimonialJobPositionAnk')}
          logo={LogoAnk_color}
          logoAlt="Logo ANK"
          color="blue"
        />
        <Testimonial
          texts={[
            t('HomeTestimonialTextClassDojo01'),
            t('HomeTestimonialTextClassDojo02'),
          ]}
          link="https://muttdata.ai/blog/2022/11/18/cdojo-cast-study.html"
          signature={t('HomeTestimonialSignatureClassDojo')}
          jobPosition={t('HomeTestimonialJobPositionClassDojo')}
          logo={LogoClassDojo_dark}
          logoAlt="Logo ClassDojo"
          color="dblue"
        />
        <Testimonial
          texts={[
            t('HomeTestimonialTextEtermax01'),
            t('HomeTestimonialTextEtermax02'),
          ]}
          link="https://muttdata.ai/blog/2023/05/04/etermax-case-study.html"
          signature={t('HomeTestimonialSignatureEtermax')}
          jobPosition={t('HomeTestimonialJobPositionEtermax')}
          logo={LogoEtermax_dark}
          logoAlt="Logo Etermax"
          color="lblue"
        />
      </Slider>
    </MainContainer>
  );
};

export default Testimonials;
