import React, { FC } from 'react';

import {
  DiagonalBottomStyled,
  DiagonalBottomInner,
  DiagonalBottomOutter,
} from './style';

interface Props {}

const DiagonalBottom: FC<Props> = () => {
  return (
    <DiagonalBottomStyled>
      <DiagonalBottomOutter color="" />
      <DiagonalBottomInner color="" />
    </DiagonalBottomStyled>
  );
};

export default DiagonalBottom;
