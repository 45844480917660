import styled from 'styled-components';

export const Container = styled.div`
  .container {
    padding: 0 20px;
  }
`;

export const Title = styled.h3`
  color: var(--black);
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 8px;
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media (min-width: 992px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Subtitle = styled.p`
  color: var(--gray);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 14px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 15px;
  position: relative;
  top: 0;
  @media (min-width: 900px) {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const Card = styled.div`
  box-sizing: border-box;
  border-radius: 0.5rem;
  background-color: var(--white);
  box-shadow: 0 0 50px 0 rgba(29, 66, 138, 0.12);
  width: 310px;
  height: 91%;
  margin: 20px;
  position: relative;
  top: 0;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 0.5rem 0.5rem 0 0;
`;

export const CardContent = styled.div`
  padding: 0 24px 26px 24px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column-reverse;
  h4 {
    color: var(--blue);
    font-family: Rubik;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 7px;
    margin-right: 15px;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    h4 {
      /* margin-top: unset; */
    }
  }
`;

export const AuthorAndPublishDate = styled.p`
  color: var(--gray);
  font-family: Rubik;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

export const AuthorAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  &:nth-child(1) {
    z-index: 50;
  }
  &:nth-child(2) {
    z-index: 40;
  }
  &:nth-child(3) {
    z-index: 30;
  }
  &:nth-child(4) {
    z-index: 20;
  }
  &:nth-child(5) {
    z-index: 10;
  }
`;

export const Summary = styled.div`
  color: var(--black);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 10px;
`;
