import React, { useState, FC } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-hook-inview';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import IconCard1 from '../../../images/home/bd-card-icon.svg';
import IconCard3 from '../../../images/home/cc-card-icon.svg';
import IconCard4 from '../../../images/home/ml-card-icon.svg';
import IconCard2 from '../../../images/home/se-card-icon.svg';
import {
  DataStyled,
  CardsContainer,
  DataSliderContainer,
  SliderFooter,
  PaginationPill,
  Card,
  Heading,
  Body,
  TextColumn,
  DataPretitle,
  DataTitle,
  DataBtn,
} from './style';

interface Props {}

const Data: FC<Props> = () => {
  const [activeSlide, setActiveSlide] = useState(1);
  const [targetRef, isInViewport] = useInView({
    unobserveOnEnter: true,
    threshold: 0.5,
  });

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      if (next > 2) {
        setActiveSlide(next + 1.2);
      } else {
        setActiveSlide(next + 1);
      }
    },
  };

  const { t } = useTranslation();

  return (
    <DataStyled>
      <div ref={targetRef} className="container">
        <CardsContainer>
          <Card
            className={
              isInViewport
                ? 'animate__animated animate__fadeInUp animate__faster animate__delay-1s'
                : 'hide'
            }
          >
            <Heading>
              <h4>{t('HomeDataCard1Title')}</h4>
              <img src={IconCard1} alt="icon" />
            </Heading>
            <Body>{t('HomeDataCard1Text')}</Body>
          </Card>
          <Card
            className={
              isInViewport
                ? 'animate__animated animate__fadeInUp animate__faster animate__delay-2s'
                : 'hide'
            }
          >
            <Heading>
              <h4>{t('HomeDataCard2Title')}</h4>
              <img src={IconCard2} alt="icon" />
            </Heading>
            <Body>{t('HomeDataCard2Text')}</Body>
          </Card>
          <Card
            className={
              isInViewport
                ? 'animate__animated animate__fadeInUp animate__faster animate__delay-3s'
                : 'hide'
            }
          >
            <Heading>
              <h4>{t('HomeDataCard3Title')}</h4>
              <img src={IconCard3} alt="icon" />
            </Heading>
            <Body>{t('HomeDataCard3Text')}</Body>
          </Card>
          <Card
            className={
              isInViewport
                ? 'animate__animated animate__fadeInUp animate__faster animate__delay-4s'
                : 'hide'
            }
          >
            <Heading>
              <h4>{t('HomeDataCard4Title')}</h4>
              <img src={IconCard4} alt="icon" />
            </Heading>
            <Body>{t('HomeDataCard4Text')}</Body>
          </Card>
        </CardsContainer>
        <TextColumn
          className={
            isInViewport
              ? 'animate__animated animate__fadeInRight animate__fadeIn animate__faster'
              : 'hide'
          }
        >
          <DataPretitle>{t('HomeDataPretitle')}</DataPretitle>
          <DataTitle>{t('HomeDataTitle')}</DataTitle>
          <Link to="/solutions">
            <DataBtn>{t('HomeDataButton')}</DataBtn>
          </Link>
        </TextColumn>
      </div>
      <DataSliderContainer>
        <DataPretitle>{t('HomeDataPretitle')}</DataPretitle>
        <DataTitle>{t('HomeDataTitle')}</DataTitle>
        <Slider {...settings}>
          <Card>
            <Heading>
              <h4>{t('HomeDataCard1Title')}</h4>
              <img src={IconCard1} alt="icon" />
            </Heading>
            <Body>{t('HomeDataCard1Text')}</Body>
          </Card>
          <Card>
            <Heading>
              <h4>{t('HomeDataCard2Title')}</h4>
              <img src={IconCard2} alt="icon" />
            </Heading>
            <Body>{t('HomeDataCard2Text')}</Body>
          </Card>
          <Card>
            <Heading>
              <h4>{t('HomeDataCard3Title')}</h4>
              <img src={IconCard3} alt="icon" />
            </Heading>
            <Body>{t('HomeDataCard3Text')}</Body>
          </Card>
          <Card>
            <Heading>
              <h4>{t('HomeDataCard4Title')}</h4>
              <img src={IconCard4} alt="icon" />
            </Heading>
            <Body>{t('HomeDataCard4Text')}</Body>
          </Card>
        </Slider>
        <SliderFooter>
          <Link to="/solutions">
            <DataBtn>{t('HomeDataButton')}</DataBtn>
          </Link>
          <PaginationPill>{activeSlide}/4</PaginationPill>
        </SliderFooter>
      </DataSliderContainer>
    </DataStyled>
  );
};

export default Data;
