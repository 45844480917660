import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import AWSLogoImg from '../../../images/home/APN-Logo_Web_Color.png';
import AstronomerLogoImg from '../../../images/home/Astronomer-logo.png';
import KaszeckLogoImg from '../../../images/home/Kaszek-logo.svg';
import GoogleCloudLogoImg from '../../../images/home/logo-google-cloud.svg';
import H20LogoImg from '../../../images/home/logo-h2o.svg';
import ProductMindsLogoImg from '../../../images/home/logo-product-minds.svg';
import { MainContainer } from '../testimonials/style';
import {
  SlideContainer,
  FeaturedCompanyContainer4,
  FeaturedCompanySubtitle,
  VerticalBar,
  FeaturedCompanyText,
  FeaturedCompanyHeader,
  PartnerLink,
  PartnerLogo,
} from './style';

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 16000,
  arrow: true,
  accessibility: true,
  // dotsClass: 'slick-dots carousel-dots',
  // className: 'slider-shadow'
};

const CompanyBlurb = ({
  title,
  subtitle,
  texts,
  imgSrc,
  link,
  linkText = 'Find out more!',
}: {
  title: string;
  subtitle: string;
  texts: string[];
  imgSrc: string;
  link?: string;
  linkText?: string;
}) => (
  <SlideContainer style={{ justifyContent: 'flex-start' }}>
    <FeaturedCompanyContainer4>
      <div className="text-container">
        <FeaturedCompanyHeader>{title}</FeaturedCompanyHeader>
        <FeaturedCompanySubtitle>{subtitle}</FeaturedCompanySubtitle>
        {texts.map((text: string, index: number) => (
          <Fragment key={index}>
            {index !== 0 ? <br /> : null}
            <FeaturedCompanyText dangerouslySetInnerHTML={{ __html: text }} />
          </Fragment>
        ))}
        {link && (
          <>
            <br />
            <PartnerLink href={link}>{linkText}</PartnerLink>
          </>
        )}
      </div>

      <VerticalBar />

      <div className="img-title-container">
        <PartnerLogo src={imgSrc} alt={`${title} logo`} />
      </div>
    </FeaturedCompanyContainer4>
  </SlideContainer>
);

const FeaturedCompanies = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Slider {...settings}>
        <CompanyBlurb
          title={t('HomeAWSHeader')}
          subtitle={t('HomeAWSSubtitle')}
          texts={[t('HomeAWSText01'), t('HomeAWSText02'), t('HomeAWSText03')]}
          imgSrc={AWSLogoImg}
          link="https://landing.muttdata.ai/mutt-data-amazon-rds-delivery"
        />
        <CompanyBlurb
          title={t('HomeGoogleCloudHeader')}
          subtitle={t('HomeGoogleCloudSubtitle')}
          texts={[t('HomeGoogleCloudText01'), t('HomeGoogleCloudText02')]}
          imgSrc={GoogleCloudLogoImg}
        />
        <CompanyBlurb
          title={t('HomeAstronomerHeader')}
          subtitle={t('HomeAstronomerSubtitle')}
          texts={[
            t('HomeAstronomerText01'),
            t('HomeAstronomerText02'),
            t('HomeAstronomerText03'),
          ]}
          imgSrc={AstronomerLogoImg}
        />
        <CompanyBlurb
          title={t('HomeKaszekHeader')}
          subtitle={t('HomeKaszekSubtitle')}
          texts={[t('HomeKaszekText01'), t('HomeKaszekText02')]}
          imgSrc={KaszeckLogoImg}
          link="https://landing.muttdata.ai/mutt-data-kaszek"
        />
        <CompanyBlurb
          title={t('HomeProductMindsHeader')}
          subtitle={t('HomeProductMindsSubtitle')}
          texts={[t('HomeProductMindsText01'), t('HomeProductMindsText02')]}
          imgSrc={ProductMindsLogoImg}
          link="https://youtu.be/qrhEyNchOZc"
          linkText={t('HomeProductMindsLinkText')}
        />
        <CompanyBlurb
          title={t('HomeH20Header')}
          subtitle={t('HomeH20Subtitle')}
          texts={[t('HomeH20Text01'), t('HomeH20Text02')]}
          imgSrc={H20LogoImg}
          link="https://muttdata.ai/blog/2023/04/21/h20-ai-partners.html"
        />
      </Slider>
    </MainContainer>
  );
};

export default FeaturedCompanies;
