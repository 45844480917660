import styled from 'styled-components';

import chevronRightBlack from '../../../images/common/chevron-right-black.svg';

export const DataStyled = styled.div`
  margin-top: 54px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  @media (min-width: 992px) {
    padding: 120px 20px;
    margin-top: 160px;
  }
`;

export const CardsContainer = styled.div`
  display: none;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (min-width: 992px) {
    display: flex;
    width: 624px;
  }
  @media (min-width: 1200px) {
    display: flex;
    width: 744px;
  }
`;

export const DataSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;

  .slick-slider {
    margin-top: 54px;
    width: 100%;
    .slick-list {
      .slick-track {
        padding: 0px 20px;
        .slick-slide {
          padding: 40px 0;
          margin: -3px 0;
          &:not(:last-of-type) {
            > div {
              margin-right: 24px;
            }
          }
        }
      }
    }
    .slick-arrow {
      top: unset;
      bottom: -86px;
      transform: unset;
      cursor: pointer;
      &.slick-prev {
        right: 25%;
        left: unset;
        transform: rotate(180deg);
        &::before {
          background-image: url('${chevronRightBlack}');
          background-size: 20px 20px;
          display: inline-block;
          width: 20px;
          height: 20px;
          content: '';
        }
        @media (min-width: 414px) {
          right: 23%;
        }
        @media (min-width: 768px) {
          right: 12%;
        }
      }
      &.slick-next {
        right: 8%;
        left: unset;
        &::before {
          background-image: url('${chevronRightBlack}');
          background-size: 20px 20px;
          display: inline-block;
          width: 20px;
          height: 20px;
          content: '';
        }
        @media (min-width: 768px) {
          right: 4%;
        }
      }
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

export const SliderFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PaginationPill = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--lgray);
  height: 56px;
  width: 105px;
  color: var(--black);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 4px;
  border-radius: 32px;
  margin-top: 48px;
  margin-right: 20px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column-reverse;
  h4 {
    color: var(--black);
    font-family: Rubik;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: 16px;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    h4 {
      margin-top: unset;
      max-width: 214px;
    }
  }
`;

export const Body = styled.div`
  color: var(--gray);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 15px;
`;

export const Card = styled.div`
  box-sizing: border-box;
  border-radius: 20px;
  background-color: var(--white);
  box-shadow: 0 0 50px 0 rgba(29, 66, 138, 0.12);
  width: 294px;
  padding: 24px;
  position: relative;
  top: 0;
  outline: none;
  &:nth-of-type(1) {
    height: 344px;
    @media (min-width: 992px) {
      height: 240px;
    }
  }
  &:nth-of-type(2) {
    height: 344px;
    @media (min-width: 992px) {
      height: 280px;
      top: 110px;
    }
  }
  &:nth-of-type(3) {
    height: 344px;
    @media (min-width: 992px) {
      height: 192px;
      top: -40px;
    }
  }
  &:nth-of-type(4) {
    height: 344px;
    @media (min-width: 992px) {
      height: 312px;
      top: 110px;
    }
  }
  &.hide {
    visibility: hidden;
  }
  @media (min-width: 992px) {
    visibility: visible;
  }
  @media (min-width: 1024px) {
    margin-bottom: 24px;
    padding: 24px;
  }
  @media (min-width: 1200px) {
    width: 360px;
    padding: 40px;
  }
`;

export const TextColumn = styled.div`
  display: none;
  &.hide {
    visibility: hidden;
  }
  @media (min-width: 992px) {
    width: 264px;
    display: block;
    margin-top: 100px;
    visibility: visible;
  }
`;

export const DataPretitle = styled.div`
  color: var(--gray);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 20px;
  @media (min-width: 992px) {
    padding: 0;
  }
`;

export const DataTitle = styled.div`
  color: var(--black);
  font-family: Rubik;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 48px;
  width: 294px;
  padding: 0 20px;
  position: relative;
  @media (min-width: 992px) {
    padding: 0;
    width: 264px;
    font-size: 56px;
    line-height: 64px;
  }
`;

export const DataBtn = styled.span`
  height: 56px;
  width: 218px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 32px;
  background-color: var(--blue);
  margin-top: 48px;
  margin-left: 20px;
  &:hover {
    background-color: var(--lblue);
    color: var(--white);
  }
  @media (min-width: 992px) {
    margin-top: 63px;
    margin-left: 0;
  }
`;
