import React, { useState, useEffect, MouseEvent, FC } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-hook-inview';
import { useTranslation } from 'react-i18next';

import chevronRightBlack from '../../../images/common/chevron-right-black.svg';
import Bg from '../../../images/home/bg.png';
import Slide1Mb from '../../../images/home/slide1-mb.png';
import Slide1Step1 from '../../../images/home/slide1-step-1.png';
import Slide1Step2 from '../../../images/home/slide1-step-2.png';
import Slide2Mb from '../../../images/home/slide2-mb.png';
import Slide2Step1 from '../../../images/home/slide2-step-1.png';
import Slide2Step2 from '../../../images/home/slide2-step-2.png';
import Slide3Mb from '../../../images/home/slide3-mb.png';
import Slide3Step1 from '../../../images/home/slide3-step-1.png';
import Slide3Step2 from '../../../images/home/slide3-step-2.png';
import { rudderanalytics } from '../../../rudderanalytics';
import {
  MainStyled,
  TextColumn,
  Title,
  Subtitle,
  Btn,
  ImageContainer,
  PaginationPill,
} from './style';

type SlideType = {
  title: String;
  subtitle: String;
};

interface Props {}

const Main: FC<Props> = () => {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideChange, setSlideChange] = useState(false);
  const [targetRef] = useInView({ unobserveOnEnter: true });

  const increaseSlide = (e: React.MouseEvent) => {
    if (activeSlide <= 1) {
      setActiveSlide(activeSlide + 1);
    }
    if (activeSlide === 2) {
      setActiveSlide(0);
    }
  };
  const decreaseSlide = (e: React.MouseEvent) => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    } else {
      setActiveSlide(2);
    }
  };

  const slides: Array<SlideType> = [
    {
      title: t('HomeMain1Title'),
      subtitle: t('HomeMain1Subtitle'),
    },
    {
      title: t('HomeMain2Title'),
      subtitle: t('HomeMain2Subtitle'),
    },
    {
      title: t('HomeMain3Title'),
      subtitle: t('HomeMain3Subtitle'),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideChange(false);
      if (activeSlide <= 1) {
        setActiveSlide(activeSlide + 1);
      }
      if (activeSlide === 2) {
        setActiveSlide(0);
      }
      setSlideChange(true);
    }, 10000);

    return () => clearInterval(interval);
  }, [activeSlide]);

  return (
    <MainStyled ref={targetRef}>
      <ImageContainer activeSlide={activeSlide}>
        <div className="slides-desktop">
          <img className="bg" src={Bg} alt="background" />
          <img
            className={activeSlide === 0 ? 'slide-0 step-1' : 'slide-0'}
            src={Slide1Step1}
            alt="slide 1 step 1"
          />
          <img
            className={activeSlide === 0 ? 'slide-0 step-2' : 'slide-0'}
            src={Slide1Step2}
            alt="slide 1 step 2"
          />
          <img
            className={activeSlide === 1 ? 'slide-1 step-1' : 'slide-1'}
            src={Slide2Step1}
            alt="slide 2 step 1"
          />
          <img
            className={activeSlide === 1 ? 'slide-1 step-2' : 'slide-1'}
            src={Slide2Step2}
            alt="slide 2 step 2"
          />
          <img
            className={activeSlide === 2 ? 'slide-2 step-1' : 'slide-2'}
            src={Slide3Step1}
            alt="slide 3 step 1"
          />
          <img
            className={activeSlide === 2 ? 'slide-2 step-2' : 'slide-2'}
            src={Slide3Step2}
            alt="slide 3 step 2"
          />
        </div>
        <div className="slides-mobile">
          <img className="slide-0" src={Slide1Mb} alt="slide 1" />
          <img className="slide-1" src={Slide2Mb} alt="slide 2" />
          <img className="slide-2" src={Slide3Mb} alt="slide 3" />
        </div>
      </ImageContainer>
      <div className="container">
        <TextColumn>
          <Title
            className={
              slideChange
                ? 'animate__animated animate__fadeIn animate__faster animate__delay-1s text-change'
                : 'animate__animated animate__fadeIn animate__faster animate__delay-1s'
            }
            activeSlide={activeSlide}
          >
            {slides[activeSlide].title}
          </Title>
          <Subtitle
            className="animate__animated animate__fadeIn animate__faster animate__delay-2s"
            activeSlide={activeSlide}
            dangerouslySetInnerHTML={{
              __html: `${slides[activeSlide].subtitle}`,
            }}
          />
          <div className="buttons-container">
            <Link
              to="/contact"
              className="animate__animated animate__fadeIn animate__faster animate__delay-3s"
              onClick={() =>
                rudderanalytics.track('go_to_contact_page', {
                  origin: 'home_main_contact_button',
                })
              }
            >
              <Btn>{t('HomeMainButton')}</Btn>
            </Link>
            <PaginationPill>
              <img
                src={chevronRightBlack}
                alt="arrow-icon"
                onClick={(e) => decreaseSlide(e)}
              />
              {activeSlide + 1}/3
              <img
                src={chevronRightBlack}
                alt="arrow-icon"
                onClick={(e) => increaseSlide(e)}
              />
            </PaginationPill>
          </div>
        </TextColumn>
      </div>
    </MainStyled>
  );
};

export default Main;
