import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  background-color: #eaeef5;
  box-sizing: border-box;
  border-radius: 32px;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 16px 52px;
  gap: 8px;
  @media (min-width: 400px) {
    border-radius: 52px;
    min-height: 180px;
  }
  @media (min-width: 910px) {
    flex-direction: row;
    padding: 16px 52px;
    padding: 16px;
  }
`;

export const Title = styled.h1`
  color: var(--black);
  font-family: Rubik;
  font-weight: bold;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 16px;
  @media (min-width: 400px) {
    font-size: 24px;
    line-height: 24px;
  }
  @media (min-width: 600px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 450px;
  font-family: Rubik;
  color: var(--black);
  font-size: 12px;
  @media (min-width: 500px) {
    font-size: 16px;
  }
  @media (min-width: 910px) {
    width: 350px;
  }
`;

export const Input = styled.input`
  position: relative;
  background-color: var(--white);
  width: 100%;
  box-sizing: border-box;
  height: 45px;
  border-radius: 25px;
  border: none;
  padding: 0 110px 0 25px;
  outline: none;
  &::placeholder {
    color: var(--gray);
    opacity: 1;
  }
  transition: 300ms box-shadow;
  &:focus,
  &:hover {
    box-shadow: 0 4px 10px -1px rgba(29, 66, 138, 0.15);
  }
`;

export const SubscribeButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  height: 29px;
  border-radius: 15px;
  padding: 0 1.2em;
  background-color: var(--lblue);
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: 200ms background-color;
  &:hover {
    background-color: var(--lblue-hover);
  }
  &:disabled {
    background-color: var(--gray);
  }
`;

export const ErrorMessage = styled.p<{ level?: 'error' | 'info' }>`
  position: absolute;
  bottom: calc(-8px - 1em);
  left: 20px;
  color: var(${({ level }) => (level === 'info' ? '--dgreen' : '--red')});
`;
