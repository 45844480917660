import styled from 'styled-components';

export const AboutStyled = styled.div`
  position: relative;
  margin: 120px 0;
  margin-bottom: 120px;
  @media (min-width: 992px) {
    margin-bottom: 260px;
  }
`;

export const Inner = styled.div`
  background: var(--dblue);
  .container {
    padding: 90px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 992px) {
      padding: 90px;
      align-items: stretch;
    }
    > a {
      width: 225px;
    }
  }
`;

export const Cols = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Col = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin-bottom: 58px;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const Title = styled.h4`
  color: var(--white);
  font-family: Rubik;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
`;

export const Text = styled.p`
  color: var(--gray);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 22px;
  @media (min-width: 992px) {
    margin-top: 35px;
  }
`;

export const Btn = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 56px;
  width: 225px;
  border: 1px solid var(--white);
  border-radius: 32px;
  color: var(--white);
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-top: 34px;
  &:hover {
    color: var(--blue);
    background: var(--white);
  }
`;
