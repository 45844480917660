import styled from 'styled-components';

export const DiagonalBottomStyled = styled.div`
  position: relative;
  width: 100%;
`;

export const DiagonalBottomOutter = styled.div<{ color: string }>`
  background: var(--lgray);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 -50%);
  padding: 20px 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  @media (min-width: 992px) {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
    padding: 50px 0;
  }
`;

export const DiagonalBottomInner = styled.div<{ color: string }>`
  background: var(--dblue);
  clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
  padding: 20px 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  @media (min-width: 992px) {
    clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
    padding: 50px 0;
  }
`;
