import { useCallback, useState } from 'react';
import axios, { AxiosError } from 'axios';

// const SUBSCRIBE_URL = 'https://webhook.site/0a4baf06-2519-40ba-9c36-ef3215c6d9d4';
const SUBSCRIBE_URL =
  'https://9oghn0itah.execute-api.us-east-1.amazonaws.com/default/subscribeToMuttNewsletterInHubSpot';

export interface SubscribeBody {
  email: string;
}

export interface SubscribeResponse {
  message: string;
}

const useSubscribe = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  const subscribe = useCallback((body: SubscribeBody) => {
    setLoading(true);
    const promise = axios.request<SubscribeResponse>({
      method: 'POST',
      url: SUBSCRIBE_URL,
      data: body,
    });
    promise.then(
      () => {
        setLoading(false);
        setError(null);
      },
      (err) => {
        setLoading(false);
        setError(err);
      }
    );
    return promise;
  }, []);

  return [subscribe, { loading, error }] as const;
};

export default useSubscribe;
