import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-hook-inview';
import { useTranslation } from 'react-i18next';

import DiagonalBottom from '../../diagonalbottom';
import DiagonalTop from '../../diagonaltop';
import { AboutStyled, Inner, Cols, Col, Title, Text, Btn } from './style';

interface Props {}

const About: FC<Props> = () => {
  const { t } = useTranslation();
  const [targetRef, isInViewport] = useInView({
    unobserveOnEnter: true,
  });
  return (
    <AboutStyled>
      <DiagonalTop />
      <Inner>
        <div ref={targetRef} className="container">
          <Cols>
            <Col
              className={
                isInViewport
                  ? 'animate__animated animate__fadeInDown animate__faster'
                  : ''
              }
            >
              <Title
                dangerouslySetInnerHTML={{ __html: t('HomeAboutCol1Title') }}
              />
              <Text
                dangerouslySetInnerHTML={{ __html: t('HomeAboutCol1Text') }}
              />
            </Col>
            <Col
              className={
                isInViewport
                  ? 'animate__animated animate__fadeInDown animate__faster animate__delay-1s'
                  : ''
              }
            >
              <Title
                dangerouslySetInnerHTML={{ __html: t('HomeAboutCol2Title') }}
              />
              <Text
                dangerouslySetInnerHTML={{ __html: t('HomeAboutCol2Text') }}
              />
            </Col>
            <Col
              className={
                isInViewport
                  ? 'animate__animated animate__fadeInDown animate__faster animate__delay-2s'
                  : ''
              }
            >
              <Title
                dangerouslySetInnerHTML={{ __html: t('HomeAboutCol3Title') }}
              />
              <Text
                dangerouslySetInnerHTML={{ __html: t('HomeAboutCol3Text') }}
              />
            </Col>
          </Cols>
          <Link
            to="/company"
            className={
              isInViewport ? 'animate__animated animate__fadeInUp' : ''
            }
          >
            <Btn>{t('HomeAboutButton')}</Btn>
          </Link>
        </div>
      </Inner>
      <DiagonalBottom />
    </AboutStyled>
  );
};

export default About;
