import styled from 'styled-components';

export const PreFooterStyled = styled.div``;

export const DiagonalRight = styled.div<{ hasContentOnTop: boolean }>`
  height: 50px;
  margin-top: ${({ hasContentOnTop }) => (hasContentOnTop ? '-75px' : '-50px')};
  background: var(--dblue);
  z-index: -1;
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  @media (min-width: 992px) {
    height: 120px;
    margin-top: ${({ hasContentOnTop }) =>
      hasContentOnTop ? '-180px' : '-120px'};
  }
`;

export const Inner = styled.div`
  background: var(--dblue);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1127px;
  padding: 50px 20px;
  gap: 50px;
  box-sizing: border-box;
  @media (min-width: 992px) {
    padding: 100px 20px 130px;
    gap: 100px;
  }
`;

export const RequestQuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Title = styled.h1`
  color: var(--white);
  font-family: Rubik;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  width: 264px;
  br {
    display: none;
  }
  @media (min-width: 992px) {
    font-size: 56px;
    line-height: 64px;
    width: 360px;
    br {
      display: inline;
    }
  }
`;

export const Text = styled.p`
  color: var(--white);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 33px;
  @media (min-width: 992px) {
    width: 744px;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
`;

export const Btn = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 56px;
  width: 191px;
  border: 1px solid var(--white);
  border-radius: 32px;
  color: var(--white);
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-top: 34px;
  &:hover {
    color: var(--blue);
    background: var(--white);
  }
`;
