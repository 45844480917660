import styled from 'styled-components';

export const MainStyled = styled.div`
  margin-top: 180px;
  @media (min-width: 310px) {
    margin-top: 160px;
  }
  @media (min-width: 385px) {
    margin-top: 140px;
  }
  @media (min-width: 660px) {
    margin-top: 112px;
  }
  @media (min-width: 992px) {
    margin-top: 142px;
  }
  .container {
    padding: 0 20px;
    .buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 32px;
      width: 100%;
      position: relative;
      z-index: 1;
      @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        height: 56px;
      }
    }
    @media (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
`;

export const ImageContainer = styled.div<{ activeSlide: number }>`
  .slides-desktop {
    display: none;
    position: relative;
    top: -265px;
    right: -140px;
    width: 100%;
    height: 100%;
    > img {
      position: absolute;
      right: 0;
      max-width: 1112px;
      width: 100%;
      height: 1110px;
      &.bg {
        opacity: 1;
        transition: opacity 1s ease;
      }
      &.slide-0 {
        opacity: 0;
        transition: opacity 1s ease;
        &.step-1 {
          opacity: 1;
          transition: opacity 1s ease 0.5s;
        }
        &.step-2 {
          opacity: 1;
          transition: opacity 1s ease 1.5s;
        }
      }
      &.slide-1 {
        opacity: 0;
        transition: opacity 1s ease;
        &.step-1 {
          opacity: 1;
          transition: opacity 1s ease 0.5s;
        }
        &.step-2 {
          opacity: 1;
          transition: opacity 1s ease 1.5s;
        }
      }
      &.slide-2 {
        opacity: 0;
        transition: opacity 1s ease;
        &.step-1 {
          opacity: 1;
          transition: opacity 1s ease 0.5s;
        }
        &.step-2 {
          opacity: 1;
          transition: opacity 1s ease 1.5s;
        }
      }
    }
    @media (min-width: 992px) {
      display: block;
    }
    @media (min-width: 1440px) {
      top: -345px;
    }
    @media (min-width: 1920px) {
      right: 100px;
    }
    @media (min-width: 2500px) {
      top: -265px;
      right: 340px;
    }
  }
  .slides-mobile {
    position: relative;
    bottom: -750px;
    right: 0;
    > img {
      position: absolute;
      bottom: -160px;
      right: -220px;
      max-width: 630px;
      height: 630px;
      width: 630px;
      &.slide-0 {
        opacity: ${(props) => (props.activeSlide === 0 ? '1' : '0')};
        transition: opacity 1s ease 0.5s;
      }
      &.slide-1 {
        opacity: ${(props) => (props.activeSlide === 1 ? '1' : '0')};
        transition: opacity 1s ease 0.5s;
      }
      &.slide-2 {
        opacity: ${(props) => (props.activeSlide === 2 ? '1' : '0')};
        transition: opacity 1s ease 0.5s;
      }
      @media (min-width: 768px) {
        bottom: 130px;
        right: -120px;
      }
    }
    @media (min-width: 992px) {
      display: none;
    }
  }
`;

export const TextColumn = styled.div`
  @media (min-width: 992px) {
    width: 100%;
  }
`;

export const Title = styled.div<{ activeSlide: number }>`
  color: var(--black);
  font-family: Rubik;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 56px;
  max-width: 456px;
  opacity: 0;
  transition: opacity 1s ease;
  height: 224px;
  &.text-change {
    opacity: 1;
    transition: opacity 1s ease;
  }
  @media (min-width: 992px) {
    font-size: 64px;
    line-height: 72px;
    min-width: 427px;
    height: 288px;
  }
`;

export const Subtitle = styled.p<{ activeSlide: number }>`
  color: var(--black);
  font-family: Rubik;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  max-width: 392px;
  transform: ${(props) =>
    props.activeSlide === 0 ? 'translateY(-55px)' : 'translateY(0)'};
  strong {
    font-weight: bold;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 32px;
    margin-top: 40px;
    max-width: 456px;
    transform: ${(props) =>
      props.activeSlide === 0 ? 'translateY(-75px)' : 'translateY(0)'};
  }
`;

export const Btn = styled.span`
  height: 56px;
  width: 149px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 32px;
  background-color: var(--blue);
  &:hover {
    background-color: var(--lblue);
    color: var(--white);
  }
`;

export const PaginationPill = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: var(--gray-pill-opacity);
  height: 56px;
  width: 149px;
  color: var(--black);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 4px 26px;
  border-radius: 32px;
  margin-top: 32px;
  > img {
    cursor: pointer;
    &:first-of-type {
      transform: rotate(180deg);
    }
  }
  @media (min-width: 992px) {
    margin-top: 132px;
    width: 105px;
    padding: 4px 16px;
    margin-top: 0;
  }
`;
