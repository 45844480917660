import styled from 'styled-components';

export const CompaniesStyled = styled.div`
  margin: 54px 0;
  .container {
    padding: 0 20px;
  }
`;

export const Title = styled.h1`
  color: var(--black);
  font-family: Rubik;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  &.hide {
    visibility: hidden;
  }
`;

export const LogosContainer = styled.div`
  min-height: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 54px;
  @media (max-width: 992px) {
    justify-content: space-around;
  }
  @media (min-width: 768px) {
    justify-content: space-evenly;
  }
  @media (min-width: 992px) {
    margin-top: 50px;
    margin-bottom: 75px;
    justify-content: space-between;
  }
`;

export const Logo = styled.div`
  max-width: 167px;
  max-height: 43px;
  margin-top: 54px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 50%;
  &:nth-of-type(1) {
    animation-delay: 0.25s !important;
  }
  &:nth-of-type(2) {
    animation-delay: 0.5s !important;
  }
  &:nth-of-type(3) {
    animation-delay: 0.75s !important;
  }
  &:nth-of-type(4) {
    animation-delay: 1s !important;
  }
  &:nth-of-type(5) {
    animation-delay: 1.25s !important;
  }
  &:nth-of-type(6) {
    animation-delay: 1.5s !important;
  }
  &:nth-of-type(7) {
    animation-delay: 1.75s !important;
  }
  &:nth-of-type(8) {
    animation-delay: 2s !important;
  }
  &:nth-of-type(9) {
    animation-delay: 2.25s !important;
  }
  &:nth-of-type(10) {
    animation-delay: 2.5s !important;
  }
  &:nth-of-type(11) {
    animation-delay: 2.75s !important;
  }
  &:nth-of-type(12) {
    animation-delay: 3s !important;
  }
  @media (max-width: 992px) {
    justify-content: center;
  }
  img {
    object-fit: cover;
    @media (min-width: 992px) {
      width: 100%;
    }
  }
  &.hide {
    visibility: hidden;
  }
  @media (min-width: 568px) {
    max-width: 50%;
  }
  @media (min-width: 992px) {
    margin: 0 1rem;
    flex-basis: unset;
  }
`;

export const CasesBtnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  @media (max-width: 992px) {
    justify-content: space-evenly;
  }
`;

export const CasesBtn = styled.span`
  height: 56px;
  width: 218px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 32px;
  background-color: var(--blue);
  &.hide {
    visibility: hidden;
  }
  &:hover {
    background-color: var(--lblue);
    color: var(--white);
  }
`;

export const SlideContainer = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
  }
  @media (min-width: 568px, max-width: 992px) {
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FeaturedCompanyContainer3 = styled.div`
  box-sizing: border-box;
  border-radius: 20px;
  background-color: var(--white);
  box-shadow: 0px 40px 75px 50px rgba(29, 66, 138, 0.12);
  /* margin: 80px 20px 240px 20px; */
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  .text-container {
    padding: 0px 25px;
  }
  img {
    width: 225px;
    height: 59px;
    margin-bottom: 20px;
  }
  @media (min-width: 400px) {
    img {
      width: 300px;
      height: 78px;
      /* margin-bottom: 20px; */
    }
  }

  @media (min-width: 992px) {
    /* margin: 80px 0px 240px 0px; */
    width: 100%;
    height: 480px;
    align-items: stretch;
    justify-content: space-around;
    padding-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
    flex-direction: row;
    img {
      width: 400px;
      height: 110px;
      /*margin-bottom: 40px;*/
      margin-bottom: 0px;
    }
    .img-title-container {
      display: flex;
      align-items: center;
      /*padding-bottom: 50px;*/
    }
  }
`;

export const FeaturedCompanyContainer4 = styled.div`
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  .text-container {
    padding: 0px 25px;
  }
  @media (min-width: 400px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  @media (min-width: 992px) {
    width: 100%;
    height: 480px;
    align-items: stretch;
    justify-content: space-around;
    padding: 60px 60px;
    flex-direction: row;
    .img-title-container {
      display: flex;
      align-items: center;
    }
  }
`;

export const PartnerLogo = styled.img`
  width: 225px;
  height: 225px;
  object-fit: contain;
  @media (min-width: 400px) {
    width: 300px;
    height: 300px;
  }
  @media (min-width: 992px) {
    width: 400px;
    height: 400px;
  }
`;

export const FeaturedCompanyText = styled.p`
  font-family: 'Rubik';
  color: var(--black);
  font-size: 16px;
  width: 100%;
  margin-top: 0px;
  .bold {
    font-weight: bold;
  }
  @media (min-width: 700px) {
    /*font-size: 22px;*/
    /*width: 30rem; */
    /*margin-right: 160px;*/
    text-align: left;
  }
  @media (min-width: 825px) {
    font-size: 16px;
    width: 27rem;
    margin: 0;
  }
  @media (min-width: 1178px) {
    /*width: 30rem;*/
    width: 470px;
  }
`;

export const FeaturedCompanyHeader = styled.h2`
  font-family: 'Rubik';
  font-weight: bold;
  font-size: 32px;
  color: var(--black);
  .partner {
    font-size: 56px;
  }
`;

export const FeaturedCompanySubtitle = styled.p`
  font-family: 'Rubik';
  font-size: 24px;
  color: var(--black);
  margin-bottom: 40px;
`;

export const VerticalBar = styled.div`
  border: 1px solid var(--lwhite);
  width: 80%;
  height: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (min-width: 992px) {
    height: 80%;
    width: 0;
    margin-right: 30px;
  }
`;

export const PartnerLink = styled.a`
  color: var(--lblue);
  font-family: Rubik;
  font-size: 20px;
  text-decoration: underline;
`;
