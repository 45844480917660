import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { rudderanalytics } from '../../rudderanalytics';
import NewsletterSubscriptionForm from './NewsletterSubscriptionForm';
import {
  PreFooterStyled,
  DiagonalRight,
  Inner,
  Title,
  Text,
  Btn,
  Container,
  RequestQuoteContainer,
} from './style';

interface Props {
  title: string;
  text: string;
  btnText: string;
  hasContentOnTop?: boolean;
  disableNewsletterSubscriptionForm?: boolean;
}

const Prefooter: FC<Props> = (props) => {
  const { hasContentOnTop = true } = props;
  let { pathname } = useLocation();
  const isCompany: boolean = pathname.split('/').includes('company');

  return (
    <PreFooterStyled>
      <DiagonalRight hasContentOnTop={hasContentOnTop} />
      <Inner>
        <Container>
          {!props.disableNewsletterSubscriptionForm && (
            <NewsletterSubscriptionForm />
          )}
          <RequestQuoteContainer>
            <Title dangerouslySetInnerHTML={{ __html: props.title }} />
            <Text>
              {props.text}
              {isCompany ? (
                <a
                  href="https://jobs.lever.co/muttdata/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Btn>{props.btnText}</Btn>
                </a>
              ) : (
                <Link
                  to="/contact"
                  onClick={() =>
                    rudderanalytics.track('go_to_contact_page', {
                      origin: 'prefooter_contact_button',
                    })
                  }
                >
                  <Btn>{props.btnText}</Btn>
                </Link>
              )}
            </Text>
          </RequestQuoteContainer>
        </Container>
      </Inner>
    </PreFooterStyled>
  );
};

export default Prefooter;
