import React, { FC } from 'react';

import {
  DiagonalTopStyled,
  DiagonalTopInner,
  DiagonalTopOutter,
} from './style';

interface Props {}

const DiagonalTop: FC<Props> = () => {
  return (
    <DiagonalTopStyled>
      <DiagonalTopOutter color="" />
      <DiagonalTopInner color="" />
    </DiagonalTopStyled>
  );
};

export default DiagonalTop;
