import React, { Suspense, FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import BlogShowcase from '../../components/blogshowcase';
import About from '../../components/home/about';
import Companies from '../../components/home/companies';
import Data from '../../components/home/data';
// import LogoBlue from "./../../images/common/logo.png";
import Main from '../../components/home/main';
import Process from '../../components/home/process';
import PreFooter from '../../components/prefooter';

interface Props {}

const Homepage: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<div />}>
      <Helmet>
        <meta
          name="description"
          content="Mutt Data is a technology company that helps you develop custom data products using Machine Learning, Data Science and Big Data to boost your business."
        />
        <title>
          Mutt Data | Boost your business with Machine Learning &amp; Big Data
        </title>
        <meta
          property="og:title"
          content="Mutt Data | Boost your business with Machine Learning &amp; Big Data"
        />
        <meta
          property="og:description"
          content="Mutt Data is a technology company that helps you develop custom data products using Machine Learning, Data Science and Big Data to boost your business."
        />
        <meta
          property="og:image"
          content="https://muttdata.ai/email/logo.png"
        />
        <meta property="og:url" content="https://muttdata.ai" />
        <meta
          name="keywords"
          content="
            machine learning machine-learning ML
            data science data-science ciencia de datos
            big data
            cloud computing AWS Amazon Web Services
            data engineering
            data products productos de datos
            data systems
          "
        />
      </Helmet>

      <Main />
      <Data />
      <About />
      <Companies />
      <Process />
      <BlogShowcase />
      <PreFooter
        title={t('HomePrefooterTitle')}
        text={t('HomePrefooterText')}
        btnText={t('HomePrefooterButton')}
      />
    </Suspense>
  );
};

export default Homepage;
