import React, { FC } from 'react';
import { useInView } from 'react-hook-inview';
import { useTranslation } from 'react-i18next';

import {
  CardsContainer,
  Card,
  Heading,
  CardImage,
  CardContent,
  Summary,
  AuthorAndPublishDate,
  AuthorAvatar,
  Title,
  Subtitle,
  Container,
} from './style';

// const BLOG_POSTS_ENDPOINT = 'https://s3.amazonaws.com/dev.muttdata.ai/blog/feed.json';
// const BLOG_POSTS_ENDPOINT = 'http://dev.muttdata.ai/blog/feed.json';
const BLOG_POSTS_ENDPOINT = '/blog/feed.json';
const NUMBER_OF_POSTS_TO_SHOW = 3;

interface FeedItem {
  id: string;
  title: string;
  url: string;
  summary: string;
  image: string;
  banner_image: string;
  date_published: string;
  date_modified: string;
  tags: string[];
  authors: { name: string; avatar: string }[];
  description: string;
  content?: string;
  _reading_time: { minutes: number; words: number; images: 6 };
}
interface FeedResponse {
  title: string;
  version: string;
  description: string;
  home_page_url: string;
  feed_url: string;
  items: FeedItem[];
}

interface Props {}

const BlogShowcase: FC<Props> = () => {
  const { t } = useTranslation();
  const [targetRef, isInViewport] = useInView({
    unobserveOnEnter: true,
  });
  const [blogPosts, setBlogPosts] = React.useState<FeedItem[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BLOG_POSTS_ENDPOINT);
        const json: FeedResponse = await response.json();
        setBlogPosts(json.items);
        setLoading(false);
      } catch (error) {
        setError(true);
      }
    };
    fetchData();
  }, []);

  const START = 0;
  const posts = blogPosts.slice(START, START + NUMBER_OF_POSTS_TO_SHOW);

  const formatDate = (date: string) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    });
    return dateTimeFormat.format(new Date(date));
  };

  const animationCN = isInViewport
    ? 'animate__animated animate__fadeInUp'
    : 'hide';

  return loading || error ? null : (
    <Container>
      <div ref={targetRef} className="container">
        <Title className={animationCN}>{t('HomeBlogShowcaseTitle')}</Title>
        <Subtitle className={animationCN}>
          {t('HomeBlogShowcaseSubtitle')}
        </Subtitle>

        <CardsContainer>
          {posts.map((post, index) => (
            <a
              key={index}
              href={post.url}
              target="_blank"
              rel="noopener noreferrer"
              className={animationCN}
            >
              <Card>
                <CardImage src={post.image} alt={post.description} />
                <CardContent>
                  <Heading
                    title={
                      post._reading_time?.minutes
                        ? `${post._reading_time.minutes} min read`
                        : undefined
                    }
                  >
                    <h4>{post.title}</h4>
                  </Heading>
                  <AuthorAndPublishDate>
                    {post.authors.map((author, i) => (
                      <AuthorAvatar
                        key={i}
                        src={author.avatar}
                        alt={author.name}
                        style={
                          i === 0
                            ? {}
                            : {
                                marginLeft: '-20px',
                              }
                        }
                      />
                    ))}
                    {formatDate(post.date_published)}
                  </AuthorAndPublishDate>
                  <Summary>{post.summary}</Summary>
                </CardContent>
              </Card>
            </a>
          ))}
        </CardsContainer>
      </div>
    </Container>
  );
};

export default BlogShowcase;
